
import Axios from 'axios';

export function LightmapApiUrl() {
  //NOTE This allows us to override the app host env on the window
  //This makes it easy to point the static client at any backend at runtime
  //Very useful in system testing -JoeP
  if (typeof window !== 'undefined') {
    return window.LIGHTMAP_APP_HOST || process.env.LIGHTMAP_APP_HOST
  } else {
    return process.env.LIGHTMAP_APP_HOST
  }
}

let currentApiClient = null;

function apiClient() {
  if (!currentApiClient) {
    currentApiClient = Axios.create({
      baseURL: LightmapApiUrl(),
      headers: {
        'Accept': 'application/json',
      },
    });
  }

  return currentApiClient
};

async function fetchCsrfToken() {
  const csrfResponse = await apiClient().get('/api/v2/idm/csrf')
  return csrfResponse.data.token
}

export function signUp({ firstName, lastName, email, password }) {
  const date = new Date();

  return apiClient()('/api/v1/users.json', {
    method: 'post',
    data: {
      cohortCode: 'EARLY_ADOPTER',
      user: {
        firstName, lastName, email, password,
        termsApprovedAt: date.toISOString(),
      }
    },
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
  })
}

export function signIn({ email, password }) {
  return apiClient()('/api/v1/users/sign_in.json', {
    method: 'post',
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
    data: {
      user: {
        email,
        password,
      }
    },
  })
}

export function acceptInvitation({ firstName, lastName, password, invitationToken }) {
  const date = new Date();

  return apiClient()('/api/v1/users/invitation.json', {
    method: 'put',
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
    data: {
      cohortCode: 'EARLY_ADOPTER',
      user: {
        firstName, lastName, password, invitationToken,
        termsApprovedAt: date.toISOString(),
      }
    },
  })
}

export function confirmAccount(email) {
  return apiClient()('/api/v1/users/confirmation.json', {
    method: 'post',
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
    data: {
      user: {
        email
      }
    },
  })
}

export function resetPassword({ resetPasswordToken, password, passwordConfirmation }) {
  return apiClient()('/api/v1/users/password.json', {
    method: 'put',
    data: {
      user: {
        resetPasswordToken,
        password,
        passwordConfirmation,
      }
    },
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
  })
}

export function requestPasswordReset(email) {
  return apiClient()('/api/v1/users/password.json', {
    method: 'post',
    data: { user: { email } },
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': fetchCsrfToken(),
    },
  })
}